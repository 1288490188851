//First four css tags are obsolete
.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-duration: 1s;
}
.BGFill {
    background-color: azure;
    width: 100%;
    height: 150%;
}
@media only screen and (min-width: 1200px){
    .about-page,
    .portfolio-page,
    .contact-page {
        
            h1 {
                font-size: 55px;
                font-family: 'Coolvetica';
                color: black;
                font-weight: 400;
                margin-top: 0;
                margin-bottom: 40px;
                position: relative;
                left: 10px;
            }
    
            p {
                opacity: 0;
                font-size: 2rem;
                color: black;
                font-family: sans-serif;
                font-weight: 300;
                min-width: fit-content;
                animation: fadeIn 1s ease-in;
                animation-fill-mode: forwards;
    
                &:nth-of-type(1) {
                    from { opacity: 0; }
                    to   { opacity: 1; }
                    animation-delay: 0.4s;
                }
                &:nth-of-type(2) {
                    from { opacity: 0; }
                    to   { opacity: 1; }
                    animation-delay: 0.65s;
                }
                &:nth-of-type(3) {
                    from { opacity: 0; }
                    to   { opacity: 1; }
                    animation-delay: .9s;
                }
            }
            .text-zone {
                position: absolute;
                left: 10%;
                top: 50%;
                transform: translateY(-50%);
                width: 35%;
                vertical-align: middle;
                display: table-cell;
                max-height: 90%;
                background-color: azure;
        }
    }
}
@media only screen and (max-width: 1200px){
    .about-page,
    .portfolio-page,
    .contact-page {
        
            h1 {
                font-size: 55px;
                font-family: 'Coolvetica';
                color: black;
                font-weight: 400;
                margin-top: 0;
                margin-bottom: 40px;
                position: relative;
                left: 10px;
            }
    
            p {
                opacity: 0;
                font-size: 1.5rem;
                color: black;
                font-family: sans-serif;
                font-weight: 300;
                min-width: fit-content;
                animation: fadeIn 1s ease-in;
                animation-fill-mode: forwards;
    
                &:nth-of-type(1) {
                    from { opacity: 0; }
                    to   { opacity: 1; }
                    animation-delay: 0.4s;
                }
                &:nth-of-type(2) {
                    from { opacity: 0; }
                    to   { opacity: 1; }
                    animation-delay: 0.65s;
                }
                &:nth-of-type(3) {
                    from { opacity: 0; }
                    to   { opacity: 1; }
                    animation-delay: .9s;
                }
            }
            .text-zone {
                position: absolute;
                left: 10%;
                top: 50%;
                transform: translateY(-50%);
                width: 70%;
                margin-left: 35px;
                vertical-align: middle;
                display: table-cell;
                max-height: 90%;
                background-color: azure;
        }
    }
}