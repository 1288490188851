.home-page {
    
    @media only screen and (min-width: 1200px){
        height: 90%;
        width: 35%;

        .text-zone {
            position: absolute;
            left: 25%;
            top: 50%;
            transform: translateY(-50%);
            width: 75%;
            max-height: 90%;
            h1 {
                color: black;
                font-size: 55px;
                margin: 0;
                font-family: 'Coolvetica';
                font-weight: 500;
            }
            h2 {
                color: black;
                font-size: 35px;
                margin: 0;
                font-family: 'Coolvetica';
                font-weight: 400;
            }
        }
        .flat-button {
            position: relative;
            left: 25%;
            top: 62%;
            color: teal;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 2px solid teal;
            margin-top: 40px;
            float: left;
            animation: fadeIn 1s .5s backwards;
            white-space: nowrap;
        
            &:hover {
                background: teal;
                color: white;
            }
        }
    }
    @media only screen and (max-width: 1200px){

        height: 10%;
        width: 75%;

        .text-zone {
            position: absolute;
            left: 28%;
            top: 10%;
            transform: translateY(-15%);
            width: 90%;
            max-height: 30%;
            h1 {
                color: black;
                font-size: 40px;
                font-family: 'Coolvetica';
                font-weight: 500;
                margin-top: 00;
            }
            h2 {
                color: black;
                font-size: 30px;
                font-family: 'Coolvetica';
                font-weight: 300;
                text-shadow: 2px 2px 1px azure;
            }
        }
        .flat-button {
            position: relative;
            left: 35%;
            top: 54%;
            color: teal;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 2px solid teal;
            // float: left;
            animation: fadeIn 1s .5s backwards;
            white-space: nowrap;
            transform: translateY(30rem);
            &:hover {
                background: teal;
                color: white;
            }
        }
    }

}

.Home-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
