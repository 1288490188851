.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}

.leaflet-bottom{
    display: none;
}

.contact-page{
    background: azure;
    
}

@media only screen and (max-width: 1200px){

    .contact-form {
        width: 100%;
        margin-top: 20px;
        .flat-button{
            position: relative;
            left: 30%;
            top: 62%;
            color: teal;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 2px solid teal;
            margin-top: 10px;
            float: left;
            animation: fadeIn 1s .5s backwards;
            white-space: nowrap;
        
            &:hover {
                background: teal;
                color: white;
            }
        }
    
        ul {
            padding: 0;
            margin: 0;
            
            li {
                padding: 0;
                margin: 0;
                list-style: none;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
                position: relative;
                opacity: 0;
                animation: fadeInUp 2s .45s;
                animation-fill-mode: forwards;
                clear: both;
            }
    
            li.half {
                width: 100%;
                margin-left: 0%;
                float: left;
                clear: none;
    
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        
        input[type='text'],
        input[type='email'] {
            width: 100%;
            border: 0;
            background: white;
            height: 50px;
            font-size: 16px;
            color: black;
            padding: 0 20px;
            box-sizing: border-box;
            border: 1px solid teal;
        }
    
        textarea {
            width: 100%;
            border: 0;
            background: white;
            height: 50px;
            font-size: 16px;
            color: black;
            padding: 20px;
            min-height: 150px;
            box-sizing: border-box;
            border: 1px solid teal;
            }
    }
    
        span {
            font-size: 16px;
            display: block;
            padding-top: 20px;
            color: white;
        }
        
        
        .map-wrap {
            background: rgba(8, 253, 216, 0.1);
            float: none;
            margin: 40px;
            padding-bottom: 40px;
            width: 80%;
            height: 40%;
            transform: translateY(600px);
        }
        
        .info-map {
            display: none; 
        }
        
        .contact-page{
            height: 100%;
            .text-zone{
            position: absolute;
            top: 40%;
            }
        }  
    }
        

@media only screen and (min-width: 1200px){
    .contact-form {
        width: 100%;
        margin-top: 20px;
        // background-color: azure;
        .flat-button{
            position: relative;
            left: 0%;
            top: 62%;
            color: teal;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: 4px;
            font-family: sans-serif;
            text-decoration: none;
            padding: 10px 18px;
            border: 2px solid teal;
            margin-top: 10px;
            float: left;
            animation: fadeIn 1s .5s backwards;
            white-space: nowrap;
        
            &:hover {
                background: teal;
                color: white;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            
            li {
                padding: 0;
                margin: 0;
                list-style: none;
                margin-bottom: 10px;
                overflow: hidden;
                display: block;
                position: relative;
                opacity: 0;
                animation: fadeInUp 2s .45s;
                animation-fill-mode: forwards;
                clear: both;
            }
    
            li.half {
                width: 49%;
                margin-left: 2%;
                float: left;
                clear: none;
    
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        
        input[type='text'],
        input[type='email'] {
            width: 100%;
            border: 0;
            background: white;
            height: 50px;
            font-size: 16px;
            color: black;
            padding: 0 20px;
            box-sizing: border-box;
            border: 1px solid teal;
        }
    
        textarea {
            width: 100%;
            border: 0;
            background: white;
            height: 50px;
            font-size: 16px;
            color: black;
            padding: 20px;
            min-height: 150px;
            box-sizing: border-box;
            border: 1px solid teal;
            }
    }

    .info-map {
        position: absolute;
        background-color: black;
        top: 50px;
        right: 30%;
        z-index: 999999;
        width: 267px;
        padding: 20px;
        color: white;
        font-family: 'Helvetica';
        font-size: 17px;
        font-weight: 300;
        opacity: 0;
        animation: fadeIn 1s 1.5s;
        animation-fill-mode: forwards;
    
        span {
            font-size: 16px;
            display: block;
            padding-top: 20px;
            color: white;
        }
    }

    .map-wrap {
        background: rgba(8, 253, 216, 0.1);
        float: right;
        width: 53%;
        height: 100%;
    }
}
