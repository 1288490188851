.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    background-color: azure;
    width: calc(100% - 150px);
    position: absolute;
    height: 200%;
    overflow: auto;

    h1.page-title {
        text-align: center;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
        align-items: center;
        justify-content: center;
    }

    .image-box {
        position: relative;
        flex: 1 1 40%;
        height: 350px;
        overflow: hidden;
        border-radius: 5px;
        max-width: calc(38% - 10px);

        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #fff, #000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

@media only screen and (max-width: 1200px){
    .container.portfolio-page {
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        
        h1.page-title {
            text-align: center;
            margin-left: 0px;
            margin-top: 10px;
        }
        
        .image-box {
            flex: 1 1 50%;
            height: 200px;
            max-width: calc(50% - 10px);
            .title {
                margin-bottom: 0;
                margin-top: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
}

@media only screen and (max-width: 1800px){
    .container.portfolio-page {
        width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 6rem;
        box-sizing: border-box;
        
        h1.page-title {
            text-align: center;
            margin-left: 0px;
            margin-top: 10px;
        }
        
        .image-box {
            flex: 1 1 40%;
            height: 290px;
            max-width: calc(40% - 10px);
            .title {
                margin-bottom: 0;
                margin-top: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
}